import React from 'react';
import Morph from './Morph';
import Seo from './Seo';

// go back home link
var Nav = () => {
    return (
    <nav>
        <input id="menu__toggle" type="checkbox" />
        <label className="menu__btn" for="menu__toggle">
        <span></span>
        </label>
        <div className="menu__box">
            <a href="/">Threedle</a>
        </div>
    </nav>
    )
}

const CourseLayout = ({children}) => {
  return (
    <>
      <Seo></Seo>
      <Nav></Nav>
      <Morph/>
      <div className="sections-wrapper">
      {children}
      </div>
      <div className="footer"></div>
    </>
  )
}

export default CourseLayout;